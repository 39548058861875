import React from "react";

import classes from "./Input.module.css";

const Input = React.forwardRef((props, ref) => {
  return (
    <div className={classes.input}>
      <input
        ref={ref}
        {...props.input}
        placeholder={props.inputPlaceHolder}
        className={props.className}
        type={props.type}
      />
    </div>
  );
});

export const InputOnChange = (props) => {
  return (
    <div className={classes.input}>
      <input
        placeholder={props.inputPlaceHolder}
        className={props.className}
        type={props.type}
        onChange={(event) => props.setSearch(event.target.value)}
      />
    </div>
  );
};

export default Input;
