import { Fragment, useEffect, useState, useRef } from "react";
import BoxTitle from "../../box/title/BoxTitle";
import BoxSubTitle from "../../box/subTitle/BoxSubTitle";
import BoxEntity from "../../box/entity/BoxEntity";
import CustomSlider from "../../customSlider/CustomSlider";

import { useStore } from "../../../hooks/store";
import slice_lists from "../../../services/slice_lists";
import replace_duplicate_label from "../../../services/replace_duplicate_label";
import removeEntityNoIcon from "../../../services/removeEntityNoIcon";
import { LoadingDot } from "../../UI/spinners/LoadingSpinners";

const Meteor = ({
  typeId,
  previewIndex,
  showSubTitle,
  maxEntities,
  title,
  group,
}) => {
  const [data, setData] = useState({ devideData: [], devideLabels: [] });
  const imgTitleRef = useRef(null);
  const titleRef = useRef(null);
  const tooltipRef = useRef(null);
  const state = useStore()[0];

  useEffect(() => {
    if (state.sensors.length > 0) {
      for (let y = 0; y < state.types.length; y++) {
        if (state.types[y].id === typeId) {
          imgTitleRef.current = state.types[y].icon;
          tooltipRef.current = state.types[y].tooltip;
          break;
        }
      }

      const sensorsInSameType = state.sensors.filter(
        (item) => item.typeId === typeId
      );
      const totalData = [];
      const totalLabels = [];

      if (group && sensorsInSameType !== undefined) {
        for (let i = 0; i < sensorsInSameType.length; i++) {
          if (sensorsInSameType[i].metrics.length === 0) break;

          const clearedEmptyEntities = removeEntityNoIcon(
            sensorsInSameType[i].metrics
          );

          const groupEmptyEntities = clearedEmptyEntities.filter(
            (item) => item.group === group
          );
          const currList = slice_lists(
            groupEmptyEntities,
            showSubTitle ? maxEntities : maxEntities + 1
          );
          const currLabels = replace_duplicate_label(
            currList.length,
            sensorsInSameType[i].title
          );

          totalData.push(...currList);
          totalLabels.push(...currLabels);
        }
      } else if (
        sensorsInSameType !== undefined &&
        sensorsInSameType.length > previewIndex &&
        sensorsInSameType[previewIndex].metrics.length !== 0
      ) {
        const clearedEmptyEntities = removeEntityNoIcon(
          sensorsInSameType[previewIndex].metrics
        );
        titleRef.current = sensorsInSameType[previewIndex].boxTitle;

        const currList = slice_lists(
          clearedEmptyEntities,
          showSubTitle ? maxEntities : maxEntities + 1
        );
        const currLabels = replace_duplicate_label(
          currList.length,
          clearedEmptyEntities.title
        );

        totalData.push(...currList);
        totalLabels.push(...currLabels);
      } else if (sensorsInSameType !== undefined && !previewIndex) {
        for (let i = 0; i < sensorsInSameType.length; i++) {
          if (sensorsInSameType[i].metrics.length === 0) break;

          const clearedEmptyEntities = removeEntityNoIcon(
            sensorsInSameType[i].metrics
          );

          const currList = slice_lists(
            clearedEmptyEntities,
            showSubTitle ? maxEntities : maxEntities + 1
          );
          const currLabels = replace_duplicate_label(
            currList.length,
            sensorsInSameType[i].title
          );

          totalData.push(...currList);
          totalLabels.push(...currLabels);
        }
      }

      setData({ devideData: totalData, devideLabels: totalLabels });
    }
  }, [state.sensors]);

  return (
    <Fragment>
      <BoxTitle
        icon={imgTitleRef.current}
        title={title}
        link={`/map?typeId=${typeId}`}
        infoContext={tooltipRef.current}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
        id={typeId}
      />
      <p style={{ fontSize: "14px", textAlign: "center", paddingTop: "5px" }}>
        <strong>{titleRef.current}</strong>
      </p>

      {data.devideData.length > 0 && (
        <CustomSlider>
          {data.devideData.map((seperateData, index) => (
            <div key={index}>
              {showSubTitle && (
                <BoxSubTitle>{data.devideLabels[index]}</BoxSubTitle>
              )}
              {seperateData.map((data, index) => (
                <BoxEntity
                  key={index}
                  icon={data.icon_large}
                  content={data.content}
                  value={data.value}
                />
              ))}
            </div>
          ))}
        </CustomSlider>
      )}
      {data.devideData.length === 0 && <LoadingDot />}
    </Fragment>
  );
};

export default Meteor;
