import classes from "./RightPanel.module.css";

const RightPanel = ({ show, className, close, children }) => {
  return (
    <div
      id={"verticalPanel"}
      className={
        show
          ? [
              classes.vertical_side_panel,
              classes.side_panel_height,
              className,
            ].join(" ")
          : [classes.vertical_side_panel, className].join(" ")
      }
    >
      <span onClick={() => close()} className={classes.close_button_sticky}>
        <p className={classes.close_button}>x</p>
      </span>
      {children}
    </div>
  );
};

export default RightPanel;
