import { cityCenter } from "../../../SMARTCITY/hardCodeData/DATA";
import MapContainer from "./container/MapContainer";
import SmartIsCityMap from "./smartiscity/SmartIsCityMap";

const Map = ({ mapFrame }) => {
  return (
    <MapContainer mapFrame={mapFrame}>
      <SmartIsCityMap />
    </MapContainer>
  );
};

export default Map;
