import classes from "./LeftPanel.module.css";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const LeftPanel = ({ open, buttonExpandName, className, children }) => {
  const [expandPanel, setExpandPanel] = useState(open);
  const panelRef = useRef(null);
  const { t } = useTranslation();
  return (
    <div
      className={
        expandPanel
          ? [
              classes.leftPanel_container,
              classes.leftPanel_width,
              className,
            ].join(" ")
          : [classes.leftPanel_container, className].join(" ")
      }
      ref={panelRef}
    >
      <button
        className={
          className && !expandPanel
            ? [classes.leftPanel_button, classes.leftPanel_button__hover].join(
                " "
              )
            : classes.leftPanel_button
        }
        type="button"
        onClick={() => setExpandPanel((prev) => !prev)}
      >
        <span>{t(buttonExpandName)}</span>
        {expandPanel ? <>&#10094;</> : <>&#10095;</>}
      </button>
      <div className={classes.leftPanel_wrapper}>{children}</div>
    </div>
  );
};

export default LeftPanel;
