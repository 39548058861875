const replace_duplicate_label = (size, label) => {
  const labels = [];

  if (size === 1) labels.push(label);
  else {
    for (let i = 0; i < size; i++) {
      labels.push(`${label} ${i + 1} / ${size}`);
    }
  }

  return labels;
};

export default replace_duplicate_label;
