import React from "react";
import classes from "./Tooltip.module.css";

const Tooltip = ({ tooltipContent, className }) => {
  return (
    <React.Fragment>
      <p id="tooltip" className={[classes.tooltip, className].join(" ")}>
        {tooltipContent}
      </p>
    </React.Fragment>
  );
};

export default Tooltip;
