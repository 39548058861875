const enableDisableActive = (list, index) => {
  const prevActiveIndex = list.findIndex((item) => item.isActive === true);
  console.log(prevActiveIndex);
  // if sometype was selected
  if (prevActiveIndex !== -1) {
    list[prevActiveIndex] = {
      ...list[prevActiveIndex],
      isActive: false,
    };
  }
  console.log(index);
  if (index !== null) {
    list[index] = {
      ...list[index],
      isActive: true,
    };
  }

  return list;
};

export default enableDisableActive;
