const slice_lists = (data, maxEntities) => {
  const subList = [];

  for (let i = 0; i < data.length; i += maxEntities) {
    subList.push(data.slice(i, i + maxEntities));
  }

  return subList;
};

export default slice_lists;
